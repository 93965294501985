@charset 'UTF-8';

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("/assets/fonts/poppins-v9-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/poppins-v9-latin-regular.woff") format("woff"); /* Modern Browsers */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("/assets/fonts/poppins-v9-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/poppins-v9-latin-500.woff")
      format("woff"); /* Modern Browsers */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("/assets/fonts/poppins-v9-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/poppins-v9-latin-600.woff")
      format("woff"); /* Modern Browsers */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("/assets/fonts/poppins-v9-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/poppins-v9-latin-700.woff")
      format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Circular/Circular-Regular-abbr.woff2") format("woff2");
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Circular/Circular-Medium-abbr.woff2") format("woff2");
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 600 700;
  font-display: swap;
  src: url("/assets/fonts/Circular/Circular-Bold-abbr.woff2") format("woff2");
}

@font-face {
  font-family: "CircularSubset";
  font-style: normal;
  font-weight: 400 500;
  src: url("/assets/fonts/Circular/Circular-Regular-subset.woff2")
    format("woff2");
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: "CircularSubset";
  font-style: normal;
  font-weight: 600 700;
  src: url("/assets/fonts/Circular/Circular-Bold-subset.woff2") format("woff2");
  unicode-range: U+0020-007F;
}

/* hide default reCaptcha badge, in order to implement custom copy & design in context */
.grecaptcha-badge {
  visibility: hidden;
}

html {
  font-size: 87.5%; /* 100% === 16px (standard browser font default), so 87.5% ===    14px */
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  font-feature-settings: "liga";
  -moz-font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  font-family: "Circular", "CircularSubset", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
